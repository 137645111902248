import React, { lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Route, Routes } from "react-router-dom";
import {
  checkAuth,
  fetchDefault,
  fetchNotice,
  fetchAllNotification,
  fetchCollegeDetails,
  fetchDecoded,
  fetchProfile,
  fetchGrievances,
} from "./actions";
import Navbar from "./pages/components/Navbar";

const Login = lazy(() => import("./pages/shared/Login"));
const AboutUs = lazy(() => import("./pages/shared/AboutUs"));
const Pricing = lazy(() => import("./pages/shared/Pricing"));
const ContactUs = lazy(() => import("./pages/shared/ContactUs"));
const PrivacyPolicy = lazy(() => import("./pages/shared/PrivacyPolicy"));
const TermsAndConditions = lazy(() =>
  import("./pages/shared/TermsAndConditions")
);
const RefundPolicy = lazy(() => import("./pages/shared/RefundPolicy"));

//Application

const ApplicationCommon = lazy(() =>
  import("./pages/shared/ApplicationCommon")
);
const ApplicationHome = lazy(() => import("./pages/application/Home"));
const ApplicationForm = lazy(() =>
  import("./pages/application/ApplicationForm")
);
// const ApplicationAcademicDetails = lazy(() =>
//   import("./pages/application/AcademicDetails")
// );
const ApplicationFeePayment = lazy(() =>
  import("./pages/application/FeePayment")
);
const StudyMaterialStaff = lazy(() =>
  import("./pages/StaffShared/StudentManagement/StudyMaterial/StudyMaterial")
);
const MyApplication = lazy(() => import("./pages/application/MyApplication"));

const StaffDashboard = lazy(() => import("./pages/StaffShared/Dashboard"));
const CreationMaster = lazy(() =>
  import("./pages/admin/CreationMaster/CreationMaster")
);
//student management
const StudentManagement = lazy(() =>
  import("./pages/StaffShared/StudentManagement/StudentManagement")
);
const Admission = lazy(() => import("./pages/StaffShared/Admission/Admission"));
const NewApplications = lazy(() =>
  import("./pages/StaffShared/Admission/NewApplications")
);
const FreshAdmission = lazy(() =>
  import("./pages/StaffShared/Admission/FreshAdmission")
);
const BatchAdmission = lazy(() =>
  import("./pages/StaffShared/Admission/BatchAdmission")
);
const AdmissionStatistics = lazy(() =>
  import("./pages/StaffShared/Admission/Statistics")
);
const StudentEntry = lazy(() =>
  import("./pages/StaffShared/Admission/StudentEntry")
);
const SearchApplication = lazy(() =>
  import("./pages/StaffShared/Admission/SearchApplication")
);
const AssignSubject = lazy(() =>
  import("./pages/StaffShared/StudentManagement/AssignSubject")
);
const SemMarkEntry = lazy(() =>
  import("./pages/StaffShared/StudentManagement/SemMarkEntry")
);
const ViewAllStudents = lazy(() =>
  import("./pages/StaffShared/StudentManagement/ViewStudents/ViewAllStudents")
);
const AssignSection = lazy(() =>
  import("./pages/StaffShared/StudentManagement/Section/AssignSection")
);
const SubjectWiseStudentDetails = lazy(() =>
  import(
    "./pages/StaffShared/StudentManagement/SubjectWiseStudentDetails/SubjectWiseStudentDetails"
  )
);
const Mentoring = lazy(() => import("./pages/faculty/Mentoring/Mentoring"));
const ChooseMentee = lazy(() =>
  import("./pages/faculty/Mentoring/ChooseMentee")
);
const MyMentees = lazy(() => import("./pages/faculty/Mentoring/MyMentees"));
const StudentDataReport = lazy(() =>
  import(
    "./pages/StaffShared/StudentManagement/StudentDataReport/StudentDataReport"
  )
);
const StudentDataReportCore = lazy(() =>
  import(
    "./pages/StaffShared/StudentManagement/StudentDataReport/StudentDataReportCore"
  )
);
const StudentDataReportElective = lazy(() =>
  import(
    "./pages/StaffShared/StudentManagement/StudentDataReport/StudentDataReportElective"
  )
);
const StudentDataReportOverall = lazy(() =>
  import(
    "./pages/StaffShared/StudentManagement/StudentDataReport/StudentDataReportOverall"
  )
);
const StudentDataReportSubjectWise = lazy(() =>
  import(
    "./pages/StaffShared/StudentManagement/StudentDataReport/StudentDataReportSubjectWise"
  )
);
const Attendance = lazy(() =>
  import("./pages/StaffShared/StudentManagement/Attendance/Attendance")
);
const MonthlyAttendance = lazy(() =>
  import("./pages/StaffShared/StudentManagement/Attendance/MonthlyAttendance")
);
const PaperwiseAttendance = lazy(() =>
  import("./pages/StaffShared/StudentManagement/Attendance/PaperwiseAttendance")
);
const AttendanceOverall = lazy(() =>
  import("./pages/StaffShared/StudentManagement/Attendance/AttendanceOverall")
);
const CatMark = lazy(() =>
  import("./pages/StaffShared/StudentManagement/CAT/CatMark")
);
const PracticalMark = lazy(() =>
  import("./pages/StaffShared/StudentManagement/Practical/PracticalMark")
);
const OralCommunication = lazy(() =>
  import(
    "./pages/StaffShared/StudentManagement/OralCommunication/OralCommunication"
  )
);
const InternalEvaluation = lazy(() =>
  import(
    "./pages/StaffShared/StudentManagement/InternalEvaluation/InternalEvaluation"
  )
);
const InternalOverview = lazy(() =>
  import(
    "./pages/StaffShared/StudentManagement/InternalEvaluation/InternalOverview"
  )
);
const SemesterMarkEntry = lazy(() =>
  import("./pages/StaffShared/StudentManagement/SemesterMark/SemesterMarkEntry")
);
const GradeCard = lazy(() =>
  import("./pages/StaffShared/StudentManagement/SemesterMark/GradeCard")
);
const OnlineAssignment = lazy(() =>
  import("./pages/StaffShared/StudentManagement/OnlineClass/OnlineAssignment")
);
const OnlineClassTest = lazy(() =>
  import("./pages/StaffShared/StudentManagement/OnlineClass/OnlineClassTest")
);
const OnlineExamination = lazy(() =>
  import("./pages/StaffShared/StudentManagement/OnlineClass/OnlineExamination")
);
//staff management
const StaffManagement = lazy(() =>
  import("./pages/admin/StaffManagement/StaffManagement")
);
const FacultyManagement = lazy(() =>
  import("./pages/admin/StaffManagement/FacultyManagement")
);
const NonTeachingStaffManagement = lazy(() =>
  import("./pages/admin/StaffManagement/NonTeachingStaffManagement")
);
const PapersAll = lazy(() =>
  import("./pages/admin/StaffManagement/ResearchAndAcademics/Papers")
);
const BooksAll = lazy(() =>
  import("./pages/admin/StaffManagement/ResearchAndAcademics/Books")
);
const ProjectsAll = lazy(() =>
  import("./pages/admin/StaffManagement/ResearchAndAcademics/Projects")
);
const PresentedAll = lazy(() =>
  import("./pages/admin/StaffManagement/ResearchAndAcademics/Presented")
);
const StaffLeaveManagement = lazy(() =>
  import("./pages/admin/StaffManagement/StaffLeaveManagement")
);

//Grievance Redressal
const SUPortal = lazy(() => import("./pages/student/SUPortal"));
const StudentUnion = lazy(() =>
  import("./pages/StaffShared/StudentUnion/StudentUnion")
);
const StudentUnionLeaders = lazy(() =>
  import("./pages/StaffShared/StudentUnion/StudentUnionLeaders")
);
const StudentUnionCandidate = lazy(() =>
  import("./pages/StaffShared/StudentUnion/StudentUnionCandidate")
);
const StudentUnionElection = lazy(() =>
  import("./pages/StaffShared/StudentUnion/StudentUnionElection")
);
const SUElection = lazy(() => import("./pages/student/SUElection"));
const GrievanceRedressal = lazy(() =>
  import("./pages/admin/Grievance/Grievance")
);

const Clubs = lazy(() => import("./pages/admin/Clubs/Clubs"));
const Club = lazy(() => import("./pages/admin/Clubs/Club"));
//IQAC Portal
const IQACPortal = lazy(() => import("./pages/admin/IQAC/IQAC"));
const DMRNew = lazy(() => import("./pages/admin/IQAC/DMR/DMRNew"));
const DMR = lazy(() => import("./pages/admin/IQAC/DMR/DMR"));
const DMRSingle = lazy(() => import("./pages/admin/IQAC/DMR/DMRSingle"));
const DSR = lazy(() => import("./pages/admin/IQAC/DSR/DSR"));
const DAR = lazy(() => import("./pages/admin/IQAC/DAR/DAR"));
const AAR = lazy(() => import("./pages/admin/IQAC/AAR/AAR"));
//Result Analysis
const ResultAnalysis = lazy(() =>
  import("./pages/StaffShared/ResultAnalysis/ResultAnalysis")
);
const CatResult = lazy(() =>
  import("./pages/StaffShared/ResultAnalysis/CatResult")
);
const SemesterMarkOverall = lazy(() =>
  import("./pages/StaffShared/ResultAnalysis/SemesterMarkOverall")
);
const AcademicHistoryComparision = lazy(() =>
  import("./pages/StaffShared/ResultAnalysis/AcademicHistoryComparision")
);
const ResultAcademicRecord = lazy(() =>
  import("./pages/StaffShared/StudentManagement/ResultAcademicRecord")
);
const SemesterResultBatch = lazy(() =>
  import("./pages/StaffShared/ResultAnalysis/SemesterResultBatch")
);
const SemesterResultYear = lazy(() =>
  import("./pages/StaffShared/ResultAnalysis/SemesterResultYear")
);
const PaperwiseResult = lazy(() =>
  import("./pages/StaffShared/ResultAnalysis/PaperwiseResult")
);

//Fee Management

const FeeManagement = lazy(() =>
  import("./pages/admin/FeeManagement/FeeManagement")
);

const AdmissionFee = lazy(() =>
  import("./pages/admin/FeeManagement/AdmissionFee")
);
const BatchInsert = lazy(() =>
  import("./pages/admin/FeeManagement/BatchInsert")
);
const RegularExaminationFee = lazy(() =>
  import("./pages/admin/FeeManagement/RegularExaminationFee")
);
const RepeaterFee = lazy(() =>
  import("./pages/admin/FeeManagement/RepeaterFee")
);

//Grievance Redressal
//RAAC Portal
const RAACAll = lazy(() => import("./pages/admin/RAAC/RAAC"));
const SelfAuthoredAll = lazy(() => import("./pages/admin/RAAC/SelfAuthored"));
const BookAll = lazy(() => import("./pages/admin/RAAC/Book"));
const PaperAll = lazy(() => import("./pages/admin/RAAC/Paper"));
const ProgrammeAll = lazy(() => import("./pages/admin/RAAC/Programme"));
const PresentationAll = lazy(() => import("./pages/admin/RAAC/Presentation"));
const TrainingAll = lazy(() => import("./pages/admin/RAAC/Training"));
const ParticipationAll = lazy(() => import("./pages/admin/RAAC/Participation"));
const ActivitiesRAACAll = lazy(() => import("./pages/admin/RAAC/Activities"));
const ResponsibilitiesInstitutionAll = lazy(() =>
  import("./pages/admin/RAAC/ResponsibilitiesInstitution")
);
const ResponsibilitiesOtherAll = lazy(() =>
  import("./pages/admin/RAAC/ResponsibilitiesOther")
);
//RAAC Faculty
const RAAC = lazy(() => import("./pages/faculty/RAAC/RAAC"));
const SelfAuthored = lazy(() => import("./pages/faculty/RAAC/SelfAuthored"));
const Book = lazy(() => import("./pages/faculty/RAAC/Book"));
const Paper = lazy(() => import("./pages/faculty/RAAC/Paper"));
const Programme = lazy(() => import("./pages/faculty/RAAC/Programme"));
const Presentation = lazy(() => import("./pages/faculty/RAAC/Presentation"));
const Training = lazy(() => import("./pages/faculty/RAAC/Training"));
const Participation = lazy(() => import("./pages/faculty/RAAC/Participation"));
const ActivitiesRAAC = lazy(() => import("./pages/faculty/RAAC/Activities"));
const ResponsibilitiesInstitution = lazy(() =>
  import("./pages/faculty/RAAC/ResponsibilitiesInstitution")
);
const ResponsibilitiesOther = lazy(() =>
  import("./pages/faculty/RAAC/ResponsibilitiesOther")
);

const LeaveApplication = lazy(() =>
  import("./pages/faculty/Leave/LeaveApplication")
);
//Routine Management
const RoutineManagement = lazy(() =>
  import("./pages/admin/RoutineManagment/RoutineManagement")
);
const DailyRoutineManagement = lazy(() =>
  import("./pages/admin/RoutineManagment/DailyRoutine")
);
const DailyRoutineFaculty = lazy(() =>
  import("./pages/faculty/RoutineManagment/DailyRoutine")
);
const Routine = lazy(() =>
  import("./pages/faculty/RoutineManagment/RoutineManagement")
);
const CatRoutineManagement = lazy(() =>
  import("./pages/admin/RoutineManagment/CatRoutine")
);
const CatRoutine = lazy(() =>
  import("./pages/faculty/RoutineManagment/CatRoutine")
);
const ExaminationRoutineManagement = lazy(() =>
  import("./pages/admin/RoutineManagment/ExaminationRoutine")
);
const DutyRosterManagement = lazy(() =>
  import("./pages/admin/RoutineManagment/DutyRoster")
);
const DutyRoster = lazy(() =>
  import("./pages/faculty/RoutineManagment/DutyRoster")
);
const ExaminationRoutine = lazy(() =>
  import("./pages/faculty/RoutineManagment/ExaminationRoutine")
);
const CollegeNewsManagement = lazy(() =>
  import("./pages/admin/News/CollegeNews")
);
const CollegeNews = lazy(() => import("./pages/faculty/News/CollegeNews"));
const Notification = lazy(() =>
  import("./pages/faculty/Notification/Notification")
);
const NotificationManagement = lazy(() =>
  import("./pages/admin/Notification/Notification")
);
const CollegeActivitiesManagement = lazy(() =>
  import("./pages/admin/Activities/CollegeActivities")
);
const CollegeActivities = lazy(() =>
  import("./pages/faculty/Activities/CollegeActivities")
);
const CollegeCalendarManagement = lazy(() =>
  import("./pages/admin/Calendar/CollegeCalendar")
);
const CollegeCalendar = lazy(() =>
  import("./pages/faculty/Calendar/CollegeCalendar")
);
const RUSA = lazy(() => import("./pages/admin/RUSA/RUSA"));
const DeveloperNotification = lazy(() =>
  import("./pages/admin/DeveloperNotification")
);
const Support = lazy(() => import("./pages/admin/Support/Support"));
const Tutorials = lazy(() => import("./pages/shared/Tutorials/Tutorials"));

const AccountSettingsAdmin = lazy(() =>
  import("./pages/admin/AccountSettings")
);
const AccountSettingsFaculty = lazy(() =>
  import("./pages/faculty/AccountSettings")
);

const Library = lazy(() => import("./pages/admin/Library/Library"));
const LibraryBooks = lazy(() => import("./pages/admin/Library/LibraryBooks"));
const LibraryDueList = lazy(() =>
  import("./pages/admin/Library/LibraryDueList")
);
const LibraryLendings = lazy(() =>
  import("./pages/admin/Library/LibraryLendings")
);

//students route
const StudentDashboard = lazy(() => import("./pages/student/Dashboard"));
const StudentMyAcademicData = lazy(() =>
  import("./pages/student/MyAcademicData")
);
const StudentAttendance = lazy(() => import("./pages/student/Attendance"));
const StudentCATMark = lazy(() => import("./pages/student/CATMark"));
const StudentInternalMark = lazy(() => import("./pages/student/InternalMark"));
const StudentMySubjectCombinations = lazy(() =>
  import("./pages/student/MySubjectCombinations")
);
const StudentCATRoutine = lazy(() => import("./pages/student/CATRoutine"));
const StudentCollegeCalendar = lazy(() =>
  import("./pages/student/CollegeCalendar")
);
const StudentCollegeNews = lazy(() => import("./pages/student/CollegeNews"));
const StudentRoutine = lazy(() => import("./pages/student/Routine"));
const StudentDailyRoutine = lazy(() => import("./pages/student/DailyRoutine"));
const StudentExaminationRoutine = lazy(() =>
  import("./pages/student/ExaminationRoutine")
);
const StudentFaculties = lazy(() => import("./pages/student/Faculties"));
const StudentGradeCard = lazy(() => import("./pages/student/GradeCard"));
const StudentGrievance = lazy(() => import("./pages/student/Grievance"));
const StudentMyClassmates = lazy(() => import("./pages/student/MyClassmates"));
const StudentMyCollege = lazy(() => import("./pages/student/MyCollege"));
const StudentNotice = lazy(() => import("./pages/student/Notice"));
const StudentCollegeActivities = lazy(() =>
  import("./pages/student/CollegeActivities")
);
const StudentOnlineClass = lazy(() => import("./pages/student/OnlineClass"));
const TeacherOnLeave = lazy(() => import("./pages/student/TeacherOnLeave"));
const StudentOnlineAssignment = lazy(() =>
  import("./pages/student/OnlineAssignment")
);
const StudentOnlineExamination = lazy(() =>
  import("./pages/student/OnlineExamination")
);
const StudentOnlineTest = lazy(() => import("./pages/student/OnlineTest"));
const StudentProfile = lazy(() => import("./pages/student/Profile"));
const StudentFee = lazy(() => import("./pages/student/Fee"));
const StudentRegularExaminationFee = lazy(() =>
  import("./pages/student/RegularExaminationFee")
);
const StudentRenewalFee = lazy(() => import("./pages/student/RenewalFee"));
const StudyMaterial = lazy(() => import("./pages/student/StudyMaterial"));
const StudentTutorials = lazy(() => import("./pages/student/Tutorials"));

const ResetPassword = lazy(() => import("./pages/shared/ResetPassword"));
const NotFound = lazy(() => import("./pages/shared/NotFound"));
const FetchPaymentDetails = lazy(() =>
  import("./pages/StaffShared/Admission/FetchPaymentDetails")
);
const App = (props) => {
  const {
    checkAuth,
    fetchDefault,
    fetchNotice,
    fetchAllNotification,
    fetchCollegeDetails,
    fetchDecoded,
    fetchProfile,
    fetchGrievances,
    auth,
    profile,
  } = props;
  useEffect(() => {
    checkAuth();
    if (auth) {
      fetchDecoded();
      fetchProfile();
      fetchDefault();
      if (auth > 3) {
        fetchAllNotification();
        fetchGrievances();
      }
      if (auth > 1 && auth < 4) {
        fetchNotice();
      }
    } else {
      fetchCollegeDetails();
    }
  }, [auth]);

  const publicRoutes = [
    {
      path: "/",
      component: <Login />,
      isShowing: true,
    },
    {
      path: "/reset-password/:user",
      component: <ResetPassword />,
      isShowing: true,
    },
    {
      path: "/application",
      component: <ApplicationCommon />,
      isShowing: true,
    },

    {
      path: "/application/login",
      component: <ApplicationCommon />,
      isShowing: true,
    },
    {
      path: "/privacy-policy",
      component: <PrivacyPolicy />,
      isShowing: true,
    },
    {
      path: "/about",
      component: <AboutUs />,
      isShowing: true,
    },
    {
      path: "/pricing",
      component: <Pricing />,
      isShowing: true,
    },
    {
      path: "/contact-us",
      component: <ContactUs />,
      isShowing: true,
    },
    {
      path: "/refund-policy",
      component: <RefundPolicy />,
      isShowing: true,
    },
    {
      path: "/terms-and-conditions",
      component: <TermsAndConditions />,
      isShowing: true,
    },
  ];

  const applicationRoutes = [
    {
      path: "/",
      component: <ApplicationHome />,
      isShowing: true,
    },
    {
      path: "/application",
      component: <ApplicationHome />,
      isShowing: true,
    },
    {
      path: "/application/login",
      component: <ApplicationHome />,
      isShowing: true,
    },
    {
      path: "/form",
      component: <ApplicationForm />,
      isShowing: true,
    },
    {
      path: "/fee-payment",
      component: <ApplicationFeePayment />,
      isShowing: true,
    },
    {
      path: "/my-application",
      component: <MyApplication />,
      isShowing: true,
    },
  ];

  const adminRoutes =
    (auth === 5 || auth === 4) && Object.keys(profile).length
      ? [
          {
            path: "/",
            component: <StaffDashboard />,
            isShowing: true,
          },
          {
            path: "/admissions",
            component: <Admission />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("H"))
                ? true
                : false,
          },
          {
            path: "/payment-details",
            component: <FetchPaymentDetails />,
            isShowing: true,
          },
          {
            path: "/admissions/new-application",
            component: <NewApplications />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("H"))
                ? true
                : false,
          },
          {
            path: "/admissions/new-application/:batch/:programme",
            component: <NewApplications />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("H"))
                ? true
                : false,
          },
          {
            path: "/admissions/fresh-admission",
            component: <FreshAdmission />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("H"))
                ? true
                : false,
          },
          {
            path: "/admissions/fresh-admission/:batch/:programme",
            component: <FreshAdmission />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("H"))
                ? true
                : false,
          },
          {
            path: "/admissions/import-student",
            component: <BatchAdmission />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("H"))
                ? true
                : false,
          },
          {
            path: "/admissions/statistics",
            component: <AdmissionStatistics />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("H"))
                ? true
                : false,
          },
          {
            path: "/admissions/student-entry",
            component: <StudentEntry />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("H"))
                ? true
                : false,
          },
          {
            path: "/admissions/search",
            component: <SearchApplication />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("H"))
                ? true
                : false,
          },
          {
            path: "/student-management",
            component: <StudentManagement />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/assign-subject",
            component: <AssignSubject />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/sem-mark",
            component: <SemMarkEntry />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/all-student",
            component: <ViewAllStudents />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/all-student/:batch/:programme",
            component: <ViewAllStudents />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/attendance",
            component: <Attendance />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/attendance/:batch/:department/:semester/:course/:year/:month",
            component: <Attendance />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/monthly-attendance",
            component: <MonthlyAttendance />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/monthly-attendance/:batch/:programme/:year/:month/:department",
            component: <MonthlyAttendance />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },

          {
            path: "/student-management/paperwise-attendance",
            component: <PaperwiseAttendance />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/paperwise-attendance/:batch/:department/:semester/:course",
            component: <PaperwiseAttendance />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/attendance-overall",
            component: <AttendanceOverall />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/attendance-overall/:batch/:programme/:semester",
            component: <AttendanceOverall />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/cat-mark",
            component: <CatMark />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/cat-mark/:batch/:department/:semester/:course/:cat",
            component: <CatMark />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/assign-section",
            component: <AssignSection />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/assign-section/:batch/:department/:semester/:course",
            component: <AssignSection />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/subjectwise-student",
            component: <SubjectWiseStudentDetails />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/subjectwise-student/:batch/:department/:semester/:course",
            component: <SubjectWiseStudentDetails />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/sdr",
            component: <StudentDataReport />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },

          {
            path: "/student-management/sdr/corewise-report",
            component: <StudentDataReportCore />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/sdr/corewise-report/:start/:end",
            component: <StudentDataReportCore />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/sdr/elective-report",
            component: <StudentDataReportElective />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/sdr/elective-report/:start/:end",
            component: <StudentDataReportElective />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/sdr/overall-report",
            component: <StudentDataReportOverall />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/sdr/overall-report/:programme/:start/:end",
            component: <StudentDataReportOverall />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/sdr/subject-wise",
            component: <StudentDataReportSubjectWise />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/sdr/subject-wise/:programme/:batch/:subject/:semester/:course",
            component: <StudentDataReportSubjectWise />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/study-material",
            component: <StudyMaterialStaff />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/study-material/:department/:semester",
            component: <StudyMaterialStaff />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/practical-mark",
            component: <PracticalMark />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/practical-mark/:batch/:department/:semester/:course",
            component: <PracticalMark />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/oral-communication",
            component: <OralCommunication />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/oral-communication/:batch",
            component: <OralCommunication />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/internal-evaluation",
            component: <InternalEvaluation />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/internal-evaluation/:batch/:department/:semester/:course",
            component: <InternalEvaluation />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/internal-overview",
            component: <InternalOverview />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/internal-overview/:batch/:programme/:semester/:department",
            component: <InternalOverview />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/semester-entry",
            component: <SemesterMarkEntry />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/semester-entry/:batch/:programme/:semester",
            component: <SemesterMarkEntry />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/grade-card",
            component: <GradeCard />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/grade-card/:batch/:programme",
            component: <GradeCard />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/academic-record",
            component: <ResultAcademicRecord />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/academic-record/:batch/:programme/:department/",
            component: <ResultAcademicRecord />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/online-assignment",
            component: <OnlineAssignment />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/online-assignment/:batch/:department/:semester/:course",
            component: <OnlineAssignment />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/online-test",
            component: <OnlineClassTest />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/online-test/:batch/:department/:semester/:course",
            component: <OnlineClassTest />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/online-examination",
            component: <OnlineExamination />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/student-management/online-examination/:batch/:department/:semester/:course",
            component: <OnlineExamination />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("A"))
                ? true
                : false,
          },
          {
            path: "/staff-management",
            component: <StaffManagement />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("B"))
                ? true
                : false,
          },
          {
            path: "/staff-management/faculty",
            component: <FacultyManagement />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("B"))
                ? true
                : false,
          },
          {
            path: "/staff-management/nts",
            component: <NonTeachingStaffManagement />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("B"))
                ? true
                : false,
          },
          {
            path: "/staff-management/research-papers",
            component: <PapersAll />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("B"))
                ? true
                : false,
          },
          {
            path: "/staff-management/books",
            component: <BooksAll />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("B"))
                ? true
                : false,
          },
          {
            path: "/staff-management/research-projects",
            component: <ProjectsAll />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("B"))
                ? true
                : false,
          },
          {
            path: "/staff-management/paper-presented",
            component: <PresentedAll />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("B"))
                ? true
                : false,
          },
          {
            path: "/staff-management/leave",
            component: <StaffLeaveManagement />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("B"))
                ? true
                : false,
          },
          {
            path: "/grievance",
            component: <GrievanceRedressal />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("C"))
                ? true
                : false,
          },
          {
            path: "/student-union",
            component: <StudentUnion />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("N"))
                ? true
                : false,
          },
          {
            path: "/student-union/candidate",
            component: <StudentUnionCandidate />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("N"))
                ? true
                : false,
          },
          {
            path: "/student-union/election",
            component: <StudentUnionElection />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("N"))
                ? true
                : false,
          },
          {
            path: "/student-union/leaders",
            component: <StudentUnionLeaders />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("N"))
                ? true
                : false,
          },
          {
            path: "/iqac",
            component: <IQACPortal />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("G"))
                ? true
                : false,
          },
          {
            path: "/iqac/dmr-new",
            component: <DMRNew />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("G"))
                ? true
                : false,
          },
          {
            path: "/iqac/dmr-new/:year/:month",
            component: <DMRNew />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("G"))
                ? true
                : false,
          },
          {
            path: "/iqac/dmr",
            component: <DMR />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("G"))
                ? true
                : false,
          },
          {
            path: "/iqac/dmr/:dmr/:department/:year/:month",
            component: <DMRSingle />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("G"))
                ? true
                : false,
          },
          {
            path: "/iqac/dsr",
            component: <DSR />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("G"))
                ? true
                : false,
          },
          {
            path: "/iqac/dar",
            component: <DAR />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("G"))
                ? true
                : false,
          },
          {
            path: "/iqac/aar",
            component: <AAR />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("G"))
                ? true
                : false,
          },
          {
            path: "/clubs",
            component: <Clubs />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("M"))
                ? true
                : false,
          },
          {
            path: "/clubs/:id/:name",
            component: <Club />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("M"))
                ? true
                : false,
          },
          {
            path: "/rusa",
            component: <RUSA />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("I"))
                ? true
                : false,
          },
          {
            path: "/library",
            component: <Library />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("O"))
                ? true
                : false,
          },
          {
            path: "/library/books",
            component: <LibraryBooks />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("O"))
                ? true
                : false,
          },
          {
            path: "/library/due-list",
            component: <LibraryDueList />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("O"))
                ? true
                : false,
          },
          {
            path: "/library/lendings",
            component: <LibraryLendings />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("O"))
                ? true
                : false,
          },
          {
            path: "/routine-management",
            component: <RoutineManagement />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("E"))
                ? true
                : false,
          },
          {
            path: "/routine-management/daily",
            component: <DailyRoutineManagement />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("E"))
                ? true
                : false,
          },
          {
            path: "/routine-management/daily/:programme/:semester",
            component: <DailyRoutineManagement />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("E"))
                ? true
                : false,
          },
          {
            path: "/routine-management/cat",
            component: <CatRoutineManagement />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("E"))
                ? true
                : false,
          },

          {
            path: "/routine-management/exam",
            component: <ExaminationRoutineManagement />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("E"))
                ? true
                : false,
          },
          {
            path: "/routine-management/duty",
            component: <DutyRosterManagement />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("E"))
                ? true
                : false,
          },
          {
            path: "/news",
            component: <CollegeNewsManagement />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("F"))
                ? true
                : false,
          },
          {
            path: "/notice",
            component: <NotificationManagement />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("F"))
                ? true
                : false,
          },
          {
            path: "/calendar",
            component: <CollegeCalendarManagement />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("F"))
                ? true
                : false,
          },
          {
            path: "/result-analysis",
            component: <ResultAnalysis />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("J"))
                ? true
                : false,
          },
          {
            path: "/result-analysis/cat",
            component: <CatResult />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("J"))
                ? true
                : false,
          },
          {
            path: "/result-analysis/cat/:batch/:programme/:semester/:cat/:department",
            component: <CatResult />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("J"))
                ? true
                : false,
          },
          {
            path: "/result-analysis/overall-semester",
            component: <SemesterMarkOverall />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("J"))
                ? true
                : false,
          },
          {
            path: "/result-analysis/overall-semester/:batch/:programme/:semester/",
            component: <SemesterMarkOverall />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("J"))
                ? true
                : false,
          },
          {
            path: "/result-analysis/academic-history",
            component: <AcademicHistoryComparision />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("J"))
                ? true
                : false,
          },
          {
            path: "/result-analysis/academic-history/:batch/:programme/:department/",
            component: <AcademicHistoryComparision />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("J"))
                ? true
                : false,
          },

          {
            path: "/result-analysis/semester",
            component: <SemesterResultBatch />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("J"))
                ? true
                : false,
          },
          {
            path: "/result-analysis/semester/:start/:end",
            component: <SemesterResultBatch />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("J"))
                ? true
                : false,
          },
          {
            path: "/result-analysis/year",
            component: <SemesterResultYear />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("J"))
                ? true
                : false,
          },
          {
            path: "/result-analysis/year/:start/:end/:semester",
            component: <SemesterResultYear />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("J"))
                ? true
                : false,
          },
          {
            path: "/result-analysis/paperwise",
            component: <PaperwiseResult />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("J"))
                ? true
                : false,
          },
          {
            path: "/result-analysis/paperwise/:batch/:department/:semester/:course",
            component: <PaperwiseResult />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("J"))
                ? true
                : false,
          },
          {
            path: "/fee-management",
            component: <FeeManagement />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("D"))
                ? true
                : false,
          },
          {
            path: "/fee-management/admission",
            component: <AdmissionFee />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("D"))
                ? true
                : false,
          },
          {
            path: "/fee-management/admission/:batch/:programme",
            component: <AdmissionFee />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("D"))
                ? true
                : false,
          },
          {
            path: "/fee-management/batch-insert",
            component: <BatchInsert />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("D"))
                ? true
                : false,
          },
          {
            path: "/fee-management/regular-examination",
            component: <RegularExaminationFee />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("D"))
                ? true
                : false,
          },
          {
            path: "/fee-management/regular-examination/:batch/:programme",
            component: <RegularExaminationFee />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("D"))
                ? true
                : false,
          },
          {
            path: "/fee-management/repeater-examination",
            component: <RepeaterFee />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("D"))
                ? true
                : false,
          },
          {
            path: "/fee-management/repeater-examination/:batch/:programme/:semester",
            component: <RepeaterFee />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("D"))
                ? true
                : false,
          },
          {
            path: "/activities",
            component: <CollegeActivitiesManagement />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("F"))
                ? true
                : false,
          },
          {
            path: "/raac",
            component: <RAACAll />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("L"))
                ? true
                : false,
          },
          {
            path: "/raac/self-authored",
            component: <SelfAuthoredAll />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("L"))
                ? true
                : false,
          },
          {
            path: "/raac/book",
            component: <BookAll />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("L"))
                ? true
                : false,
          },
          {
            path: "/raac/paper",
            component: <PaperAll />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("L"))
                ? true
                : false,
          },
          {
            path: "/raac/programme",
            component: <ProgrammeAll />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("L"))
                ? true
                : false,
          },
          {
            path: "/raac/presentation",
            component: <PresentationAll />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("L"))
                ? true
                : false,
          },
          {
            path: "/raac/training",
            component: <TrainingAll />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("L"))
                ? true
                : false,
          },
          {
            path: "/raac/participation",
            component: <ParticipationAll />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("L"))
                ? true
                : false,
          },
          {
            path: "/raac/activities",
            component: <ActivitiesRAACAll />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("L"))
                ? true
                : false,
          },
          {
            path: "/raac/responsibilities-institution",
            component: <ResponsibilitiesInstitutionAll />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("L"))
                ? true
                : false,
          },
          {
            path: "/raac/responsibilities-other",
            component: <ResponsibilitiesOtherAll />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("L"))
                ? true
                : false,
          },
          {
            path: "/developer-notification",
            component: <DeveloperNotification />,
            isShowing: true,
          },
          {
            path: "/support",
            component: <Support />,
            isShowing: true,
          },
          {
            path: "/tutorials",
            component: <Tutorials />,
            isShowing: true,
          },
          {
            path: "/creation-master",
            component: <CreationMaster />,
            isShowing:
              profile.role &&
              (profile.role.includes("X") || profile.role.includes("K"))
                ? true
                : false,
          },
          {
            path: "/account",
            component: <AccountSettingsAdmin />,
            isShowing: true,
          },
        ]
      : [];

  const facultyRoutes =
    auth === 3 && Object.keys(profile).length
      ? [
          {
            path: "/",
            component: <StaffDashboard />,
            isShowing: true,
          },
          {
            path: "/admissions",
            component: <Admission />,
            isShowing: profile.type === "Head Of Department" ? true : false,
          },
          {
            path: "/admissions/new-application",
            component: <NewApplications />,
            isShowing: profile.type === "Head Of Department" ? true : false,
          },
          {
            path: "/admissions/new-application/:batch/:programme",
            component: <NewApplications />,
            isShowing: profile.type === "Head Of Department" ? true : false,
          },
          {
            path: "/admissions/fresh-admission",
            component: <FreshAdmission />,
            isShowing: profile.type === "Head Of Department" ? true : false,
          },
          {
            path: "/admissions/fresh-admission/:batch/:programme",
            component: <FreshAdmission />,
            isShowing: profile.type === "Head Of Department" ? true : false,
          },
          {
            path: "/admissions/import-student",
            component: <BatchAdmission />,
            isShowing: profile.type === "Head Of Department" ? true : false,
          },
          {
            path: "/admissions/student-entry",
            component: <StudentEntry />,
            isShowing: profile.type === "Head Of Department" ? true : false,
          },
          {
            path: "/admissions/search",
            component: <SearchApplication />,
            isShowing: true,
          },
          {
            path: "/admissions/statistics",
            component: <AdmissionStatistics />,
            isShowing: profile.type === "Head Of Department" ? true : false,
          },
          {
            path: "/student-management",
            component: <StudentManagement />,
            isShowing: true,
          },
          {
            path: "/student-management/assign-section",
            component: <AssignSection />,
            isShowing: true,
          },
          {
            path: "/student-management/assign-section/:batch/:department/:semester/:course",
            component: <AssignSection />,
            isShowing: true,
          },
          {
            path: "/student-management/subjectwise-student",
            component: <SubjectWiseStudentDetails />,
            isShowing: true,
          },
          {
            path: "/student-management/subjectwise-student/:batch/:department/:semester/:course",
            component: <SubjectWiseStudentDetails />,
            isShowing: true,
          },
          {
            path: "/student-management/sdr",
            component: <StudentDataReport />,
            isShowing: true,
          },
          {
            path: "/student-management/sdr/corewise-report",
            component: <StudentDataReportCore />,
            isShowing: true,
          },
          {
            path: "/student-management/sdr/corewise-report/:start/:end",
            component: <StudentDataReportCore />,
            isShowing: true,
          },
          {
            path: "/student-management/sdr/elective-report",
            component: <StudentDataReportElective />,
            isShowing: true,
          },
          {
            path: "/student-management/sdr/elective-report/:start/:end",
            component: <StudentDataReportElective />,
            isShowing: true,
          },
          {
            path: "/student-management/sdr/overall-report",
            component: <StudentDataReportOverall />,
            isShowing: true,
          },
          {
            path: "/student-management/sdr/overall-report/:programme/:start/:end",
            component: <StudentDataReportOverall />,
            isShowing: true,
          },
          {
            path: "/student-management/study-material",
            component: <StudyMaterialStaff />,
            isShowing: true,
          },
          {
            path: "/student-management/study-material/:department/:semester",
            component: <StudyMaterialStaff />,
            isShowing: true,
          },
          {
            path: "/student-management/all-student",
            component: <ViewAllStudents />,
            isShowing: true,
          },
          {
            path: "/student-management/all-student/:batch/:programme",
            component: <ViewAllStudents />,
            isShowing: true,
          },
          {
            path: "/student-management/attendance",
            component: <Attendance />,
            isShowing: true,
          },
          {
            path: "/student-management/attendance/:batch/:department/:semester/:course/:year/:month",
            component: <Attendance />,
            isShowing: true,
          },
          {
            path: "/student-management/monthly-attendance",
            component: <MonthlyAttendance />,
            isShowing: true,
          },
          {
            path: "/student-management/monthly-attendance/:batch/:programme/:year/:month/:department",
            component: <MonthlyAttendance />,
            isShowing: true,
          },
          {
            path: "/student-management/paperwise-attendance",
            component: <PaperwiseAttendance />,
            isShowing: true,
          },
          {
            path: "/student-management/paperwise-attendance/:batch/:department/:semester/:course",
            component: <PaperwiseAttendance />,
            isShowing: true,
          },
          {
            path: "/student-management/attendance-overall",
            component: <AttendanceOverall />,
            isShowing: true,
          },
          {
            path: "/student-management/attendance-overall/:batch/:programme/:semester",
            component: <AttendanceOverall />,
            isShowing: true,
          },
          {
            path: "/student-management/cat-mark",
            component: <CatMark />,
            isShowing: true,
          },
          {
            path: "/student-management/cat-mark/:batch/:department/:semester/:course/:cat",
            component: <CatMark />,
            isShowing: true,
          },
          {
            path: "/student-management/practical-mark",
            component: <PracticalMark />,
            isShowing: true,
          },
          {
            path: "/student-management/practical-mark/:batch/:department/:semester/:course",
            component: <PracticalMark />,
            isShowing: true,
          },
          {
            path: "/student-management/oral-communication",
            component: <OralCommunication />,
            isShowing: true,
          },
          {
            path: "/student-management/oral-communication/:batch",
            component: <OralCommunication />,
            isShowing: true,
          },
          {
            path: "/student-management/internal-evaluation",
            component: <InternalEvaluation />,
            isShowing: true,
          },
          {
            path: "/student-management/internal-evaluation/:batch/:department/:semester/:course",
            component: <InternalEvaluation />,
            isShowing: true,
          },
          {
            path: "/student-management/internal-overview",
            component: <InternalOverview />,
            isShowing: true,
          },
          {
            path: "/student-management/internal-overview/:batch/:programme/:semester/:department",
            component: <InternalOverview />,
            isShowing: true,
          },
          {
            path: "/student-management/semester-entry",
            component: <SemesterMarkEntry />,
            isShowing: true,
          },
          {
            path: "/student-management/semester-entry/:batch/:programme/:semester",
            component: <SemesterMarkEntry />,
            isShowing: true,
          },
          {
            path: "/student-management/grade-card",
            component: <GradeCard />,
            isShowing: true,
          },
          {
            path: "/student-management/academic-record",
            component: <ResultAcademicRecord />,
            isShowing: true,
          },
          {
            path: "/student-management/academic-record/:batch/:programme/:department/",
            component: <ResultAcademicRecord />,
            isShowing: true,
          },
          {
            path: "/student-management/grade-card/:batch/:programme",
            component: <GradeCard />,
            isShowing: true,
          },
          {
            path: "/student-management/online-assignment",
            component: <OnlineAssignment />,
            isShowing: true,
          },
          {
            path: "/student-management/online-assignment/:batch/:department/:semester/:course",
            component: <OnlineAssignment />,
            isShowing: true,
          },
          {
            path: "/student-management/online-test",
            component: <OnlineClassTest />,
            isShowing: true,
          },
          {
            path: "/student-management/online-test/:batch/:department/:semester/:course",
            component: <OnlineClassTest />,
            isShowing: true,
          },
          {
            path: "/student-management/online-examination",
            component: <OnlineExamination />,
            isShowing: true,
          },
          {
            path: "/student-management/online-examination/:batch/:department/:semester/:course",
            component: <OnlineExamination />,
            isShowing: true,
          },

          {
            path: "/mentoring",
            component: <Mentoring />,
            isShowing: true,
          },
          {
            path: "/mentoring/choose-mentee",
            component: <ChooseMentee />,
            isShowing: true,
          },
          {
            path: "/mentoring/choose-mentee/:batch/:programme/:department",
            component: <ChooseMentee />,
            isShowing: true,
          },
          {
            path: "/mentoring/my-mentees",
            component: <MyMentees />,
            isShowing: true,
          },
          {
            path: "/mentoring/my-mentees/:batch/:programme/:department",
            component: <MyMentees />,
            isShowing: true,
          },

          {
            path: "/raac",
            component: <RAAC />,
            isShowing: true,
          },
          {
            path: "/raac/self-authored",
            component: <SelfAuthored />,
            isShowing: true,
          },
          {
            path: "/raac/book",
            component: <Book />,
            isShowing: true,
          },
          {
            path: "/raac/paper",
            component: <Paper />,
            isShowing: true,
          },
          {
            path: "/raac/programme",
            component: <Programme />,
            isShowing: true,
          },
          {
            path: "/raac/presentation",
            component: <Presentation />,
            isShowing: true,
          },
          {
            path: "/raac/training",
            component: <Training />,
            isShowing: true,
          },
          {
            path: "/raac/participation",
            component: <Participation />,
            isShowing: true,
          },
          {
            path: "/raac/activities",
            component: <ActivitiesRAAC />,
            isShowing: true,
          },
          {
            path: "/raac/responsibilities-institution",
            component: <ResponsibilitiesInstitution />,
            isShowing: true,
          },
          {
            path: "/raac/responsibilities-other",
            component: <ResponsibilitiesOther />,
            isShowing: true,
          },

          {
            path: "/leave",
            component: <LeaveApplication />,
            isShowing: true,
          },
          {
            path: "/student-union",
            component: <StudentUnion />,
            isShowing: true,
          },
          {
            path: "/student-union/candidate",
            component: <StudentUnionCandidate />,
            isShowing: true,
          },
          {
            path: "/student-union/election",
            component: <StudentUnionElection />,
            isShowing: true,
          },
          {
            path: "/student-union/leaders",
            component: <StudentUnionLeaders />,
            isShowing: true,
          },
          {
            path: "/iqac",
            component: <IQACPortal />,
            isShowing: true,
          },
          {
            path: "/iqac/dmr-new",
            component: <DMRNew />,
            isShowing: true,
          },
          {
            path: "/iqac/dmr-new/:year/:month",
            component: <DMRNew />,
            isShowing: true,
          },
          {
            path: "/iqac/dmr",
            component: <DMR />,
            isShowing: true,
          },
          {
            path: "/iqac/dmr/:dmr/:department/:year/:month",
            component: <DMRSingle />,
            isShowing: true,
          },
          {
            path: "/iqac/dsr",
            component: <DSR />,
            isShowing: true,
          },
          {
            path: "/iqac/dar",
            component: <DAR />,
            isShowing: true,
          },
          {
            path: "/iqac/aar",
            component: <AAR />,
            isShowing: true,
          },
          {
            path: "/routine",
            component: <Routine />,
            isShowing: true,
          },

          {
            path: "/routine/daily",
            component: <DailyRoutineFaculty />,
            isShowing: true,
          },
          {
            path: "/routine/daily/:programme/:semester",
            component: <DailyRoutineFaculty />,
            isShowing: true,
          },
          {
            path: "/routine/cat",
            component: <CatRoutine />,
            isShowing: true,
          },
          {
            path: "/routine/exam",
            component: <ExaminationRoutine />,
            isShowing: true,
          },
          {
            path: "/routine/duty",
            component: <DutyRoster />,
            isShowing: true,
          },
          {
            path: "/news",
            component: <CollegeNews />,
            isShowing: true,
          },
          {
            path: "/notice",
            component: <Notification />,
            isShowing: true,
          },
          {
            path: "/calendar",
            component: <CollegeCalendar />,
            isShowing: true,
          },
          {
            path: "/result-analysis",
            component: <ResultAnalysis />,
            isShowing: true,
          },
          {
            path: "/result-analysis/cat",
            component: <CatResult />,
            isShowing: true,
          },
          {
            path: "/result-analysis/cat/:batch/:programme/:semester/:cat/:department",
            component: <CatResult />,
            isShowing: true,
          },
          {
            path: "/result-analysis/semester",
            component: <SemesterResultBatch />,
            isShowing: true,
          },
          {
            path: "/result-analysis/semester/:start/:end",
            component: <SemesterResultBatch />,
            isShowing: true,
          },
          {
            path: "/result-analysis/year",
            component: <SemesterResultYear />,
            isShowing: true,
          },
          {
            path: "/result-analysis/year/:start/:end/:semester",
            component: <SemesterResultYear />,
            isShowing: true,
          },
          {
            path: "/result-analysis/overall-semester",
            component: <SemesterMarkOverall />,
            isShowing: true,
          },
          {
            path: "/result-analysis/overall-semester/:batch/:programme/:semester/",
            component: <SemesterMarkOverall />,
            isShowing: true,
          },
          {
            path: "/result-analysis/paperwise",
            component: <PaperwiseResult />,
            isShowing: true,
          },
          {
            path: "/result-analysis/paperwise/:batch/:department/:semester/:course",
            component: <PaperwiseResult />,
            isShowing: true,
          },
          {
            path: "/result-analysis/academic-history",
            component: <AcademicHistoryComparision />,
            isShowing: true,
          },
          {
            path: "/result-analysis/academic-history/:batch/:programme/:department/",
            component: <AcademicHistoryComparision />,
            isShowing: true,
          },
          {
            path: "/activities",
            component: <CollegeActivities />,
            isShowing: true,
          },
          {
            path: "/tutorials",
            component: <Tutorials />,
            isShowing: true,
          },
          {
            path: "/account",
            component: <AccountSettingsFaculty />,
            isShowing: true,
          },
        ]
      : [];
  const studentRoutes = [
    {
      path: "/",
      component: <StudentDashboard />,
      isShowing: true,
    },
    {
      path: "/academic",
      component: <StudentMyAcademicData />,
      isShowing: true,
    },
    {
      path: "/academic/attendance",
      component: <StudentAttendance />,
      isShowing: true,
    },
    {
      path: "/academic/cat-mark",
      component: <StudentCATMark />,
      isShowing: true,
    },
    {
      path: "/academic/internal-mark",
      component: <StudentInternalMark />,
      isShowing: true,
    },
    {
      path: "/academic/grade-card",
      component: <StudentGradeCard />,
      isShowing: true,
    },
    {
      path: "/academic/subject-combinations",
      component: <StudentMySubjectCombinations />,
      isShowing: true,
    },
    {
      path: "/teacher-on-leave",
      component: <TeacherOnLeave />,
      isShowing: true,
    },
    {
      path: "/online-class",
      component: <StudentOnlineClass />,
      isShowing: true,
    },
    {
      path: "/online-class/assignment",
      component: <StudentOnlineAssignment />,
      isShowing: true,
    },
    {
      path: "/online-class/test",
      component: <StudentOnlineTest />,
      isShowing: true,
    },
    {
      path: "/online-class/examination",
      component: <StudentOnlineExamination />,
      isShowing: true,
    },
    {
      path: "/my-classmates",
      component: <StudentMyClassmates />,
      isShowing: true,
    },
    {
      path: "/faculties",
      component: <StudentFaculties />,
      isShowing: true,
    },
    {
      path: "/grievance",
      component: <StudentGrievance />,
      isShowing: true,
    },
    {
      path: "/fee",
      component: <StudentFee />,
      isShowing: true,
    },
    {
      path: "/fee/renewal",
      component: <StudentRenewalFee />,
      isShowing: true,
    },
    {
      path: "/fee/regular",
      component: <StudentRegularExaminationFee />,
      isShowing: true,
    },
    {
      path: "/routine",
      component: <StudentRoutine />,
      isShowing: true,
    },
    {
      path: "/routine/daily",
      component: <StudentDailyRoutine />,
      isShowing: true,
    },
    {
      path: "/routine/daily/:programme/:semester/:day",
      component: <StudentDailyRoutine />,
      isShowing: true,
    },
    {
      path: "/routine/cat",
      component: <StudentCATRoutine />,
      isShowing: true,
    },
    {
      path: "/routine/exam",
      component: <StudentExaminationRoutine />,
      isShowing: true,
    },
    {
      path: "/notice",
      component: <StudentNotice />,
      isShowing: true,
    },
    {
      path: "/news",
      component: <StudentCollegeNews />,
      isShowing: true,
    },
    {
      path: "/activities",
      component: <StudentCollegeActivities />,
      isShowing: true,
    },
    {
      path: "/calendar",
      component: <StudentCollegeCalendar />,
      isShowing: true,
    },
    {
      path: "/my-college",
      component: <StudentMyCollege />,
      isShowing: true,
    },
    {
      path: "/study-material",
      component: <StudyMaterial />,
      isShowing: true,
    },
    {
      path: "/study-material/:department/:semester",
      component: <StudyMaterial />,
      isShowing: true,
    },
    {
      path: "/student-union",
      component: <SUPortal />,
      isShowing: true,
    },
    {
      path: "/student-union/election",
      component: <SUElection />,
      isShowing: true,
    },
    {
      path: "/tutorials",
      component: <StudentTutorials />,
      isShowing: true,
    },
    {
      path: "/account",
      component: <StudentProfile />,
      isShowing: true,
    },
  ];

  if (auth === 5 || auth === 4) {
    //admin
    return (
      <Navbar>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 300,
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <Routes>
            <Route path="*" element={<NotFound />} />
            {adminRoutes
              .filter((item) => item.isShowing)
              .map((item) => (
                <Route
                  key={item.path}
                  path={item.path}
                  exact
                  element={item.component}
                />
              ))}
          </Routes>
        </Suspense>
      </Navbar>
    );
  } else if (auth === 3) {
    //faculty
    return (
      <Navbar>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 300,
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <Routes>
            <Route path="*" element={<NotFound />} />
            {facultyRoutes
              .filter((item) => item.isShowing)
              .map((item) => (
                <Route
                  key={item.path}
                  path={item.path}
                  exact
                  element={item.component}
                />
              ))}
          </Routes>
        </Suspense>
      </Navbar>
    );
  } else if (auth === 2) {
    //student
    return (
      <Navbar>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 300,
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <Routes>
            <Route path="*" element={<NotFound />} />
            {studentRoutes.map((item) => (
              <Route
                key={item.path}
                path={item.path}
                exact
                element={item.component}
              />
            ))}
          </Routes>
        </Suspense>
      </Navbar>
    );
  } else if (auth === 1) {
    //parent
    return (
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 300,
            }}
          >
            <CircularProgress />
          </div>
        }
      >
        <Routes>
          <Route path="*" element={<NotFound />} />
          {applicationRoutes.map((item) => (
            <Route
              key={item.path}
              path={item.path}
              exact
              element={item.component}
            />
          ))}
        </Routes>
      </Suspense>
    );
  } else if (auth === 0) {
    //public
    return (
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 300,
            }}
          >
            <CircularProgress />
          </div>
        }
      >
        <Routes>
          <Route path="*" element={<NotFound />} />
          {publicRoutes.map((item) => (
            <Route
              key={item.path}
              path={item.path}
              exact
              element={item.component}
            />
          ))}
        </Routes>
      </Suspense>
    );
  } else {
    return (
      <div
        style={{
          marginTop: 300,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return { auth: state.auth, profile: state.profile };
};
export default connect(mapStateToProps, {
  checkAuth,
  fetchDefault,
  fetchNotice,
  fetchAllNotification,
  fetchCollegeDetails,
  fetchDecoded,
  fetchProfile,
  fetchGrievances,
})(App);
